import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { ReactNode } from "react";

interface CardActionProps {
    text: string;
    to?: string;
    icon: ReactNode;
    onClick?: () => void;
    internalLink?: boolean;
    download?: string;
}

const CardAction = ({
                        internalLink,
                        text,
                        to,
                        onClick,
                        icon,
                        download,
                    }: CardActionProps) => {

    const content = (
        <Grid
            container
            direction={"row"}
            alignItems={"center"}
            mb={1}
            flexWrap={"nowrap"}
            sx={{
                textDecoration: "none",
                color: "#636363",
                "&:hover": {
                    cursor: "pointer",
                },
                "&:hover .MuiTypography-root": {
                    textDecoration: "underline",
                    color: "primary.main",
                },
            }}
        >
            <Grid item mr={2}>
                <Box
                    sx={{
                        background: "white",
                        border: "1px solid #E8E6F8",
                        padding: "2px",
                        borderRadius: "5px",
                        lineHeight: 0,
                    }}
                >
                    {icon}
                </Box>
            </Grid>
            <Grid item zeroMinWidth>
                <Typography noWrap variant={"body1"}>
                    {text}
                </Typography>
            </Grid>
        </Grid>
    );

    if (to) {
        if (!internalLink) {
            return (
                <Link
                    component={NavLink}
                    to={to}
                    onClick={onClick}
                    color={"#636363"}
                    underline={"hover"}
                >
                    {content}
                </Link>
            );
        } else {
            return (
                <Link
                    download={download}
                    href={to}
                    onClick={onClick}
                    color={"#636363"}
                    underline={"hover"}
                >
                    {content}
                </Link>
            );
        }
    } else {
        return <Box onClick={onClick}>{content}</Box>;
    }
};

export default CardAction;
