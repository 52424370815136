import {formatUserName, UserStats} from "../../model/User";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table, TableRow,
} from "@mui/material";
import {useSnackbar} from "notistack";
import React, {useCallback, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
    getLastUserEmailConfirmationApiRoute,
    postUserEmailConfirmationApiRoute,
    UserEmailConfirmationResponse
} from "../../api/routes/users";
import InitialLoading from "../shared/InitialLoading";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {dashboardDate} from "../../utils/dateFormats";

interface UserEmailConfirmationDialogProps {
    isOpen: boolean;
    user?: UserStats;
    onClose: () => void;
}

const UserEmailConfirmationDialog = ({isOpen, user, onClose}: UserEmailConfirmationDialogProps) => {

    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isSending, setIsSending] = useState<boolean>(false);

    const [emailConfirmation, setEmailConfirmation] = useState<UserEmailConfirmationResponse | undefined>(undefined)

    const repeatEmailConfirmation = async () => {
        try {
            if (user) {
                await postUserEmailConfirmationApiRoute(user.id)
                onClose();
            }
            enqueueSnackbar(`  Email was sent to ${emailConfirmation?.email}`, {
                variant: "success",
            });
        } catch (error: any) {
            enqueueSnackbar("  Can't send email confirmation", {
                variant: "error",
            });
        } finally {
            setIsSending(false);
        }
    }

    const loadLastUserEmailConfirmation = useCallback(async (userID) => {
        try {
            setIsLoading(true);
            const lastConfirmation = await getLastUserEmailConfirmationApiRoute(userID);
            setEmailConfirmation(lastConfirmation);
        } catch (error: any) {
            enqueueSnackbar(`  Something went wrong: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setIsLoading(false);
        }
    }, [enqueueSnackbar]);

    useEffect(() => {
        if (user && isOpen) {
            loadLastUserEmailConfirmation(user.id).then();
        }
    }, [user, isOpen, loadLastUserEmailConfirmation]);

    return (
        <Dialog open={isOpen} fullWidth maxWidth={"md"} disableRestoreFocus>
            <DialogTitle>Send email confirmation to {user && formatUserName(user)}</DialogTitle>
            <DialogContent>
                <Grid container columnSpacing={2} rowSpacing={1} pl={2} pr={2}>
                    {isLoading ? (
                        <Grid item xs={12} sm={12}>
                            <InitialLoading/>
                        </Grid>
                    ) : emailConfirmation && (
                        <React.Fragment>
                            <Grid item xs={12} sm={6}>
                                Repeat sending of email confirmation message. Last message is below.
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>To email:</TableCell>
                                            <TableCell>{emailConfirmation.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Created at:</TableCell>
                                            <TableCell>{dashboardDate(emailConfirmation.createdAt)} </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    disabled={true}
                                    sx={{width: "100%"}}
                                    id="confirmationText"
                                    label="Confirmation email text"
                                    value={emailConfirmation.payload}
                                    multiline={true}
                                    required
                                    rows={10}
                                    style={{textAlign: 'left'}}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={() => onClose()}>
                    Cancel
                </Button>
                <LoadingButton
                    variant={"contained"}
                    loading={isSending}
                    onClick={repeatEmailConfirmation}
                >
                    Send email
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default UserEmailConfirmationDialog;