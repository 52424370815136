import PaymentLinkInfo from "../../model/PaymentLinkInfo";
import CardLayout from "./CardLayout";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useMemo} from "react";
import CopyLinkPrompt from "../shared/CopyLinkPrompt";
import QrCodeWrapper from "../shared/QrCodeWrapper";
import CardAction from "../shared/CardAction";
import DownloadIcon from "@mui/icons-material/Download";
import CardInfo from "../shared/CardInfo";
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import {formatCurrencyCodeSign} from "../../model/Payment";


interface QRCodeCardProps {
    paymentLinkInfo: PaymentLinkInfo;
    showActions?: boolean;
}

const QRCodePaymentsCard = ({
                                paymentLinkInfo,
                                showActions,
                            }: QRCodeCardProps) => {

    const hasSeparateBalance = useMemo(() => {
        return paymentLinkInfo?.paymentLocalCurrency !== paymentLinkInfo?.paymentBalanceCurrency;
    }, [paymentLinkInfo?.paymentLocalCurrency, paymentLinkInfo?.paymentBalanceCurrency])

    return (
        <CardLayout
            id={paymentLinkInfo.id}
            showActions={showActions}
            picture={<QrCodeWrapper paymentLink={paymentLinkInfo}/>}
            main={
                <React.Fragment>
                    <Grid item zeroMinWidth maxWidth={"100%"}>
                        <Typography
                            noWrap
                            variant={"h2"}
                            sx={{
                                maxWidth: "100%",
                            }}
                        >
                            {paymentLinkInfo.description}
                        </Typography>
                    </Grid>
                    <Grid item sx={{display: {xs: "none", sm: "flex"}}}>
                        <CopyLinkPrompt payload={paymentLinkInfo}/>
                    </Grid>
                </React.Fragment>
            }
            mainProps={{spaceBetween: true}}
            actions={(inPopupMenu, closeVertMenu) => (
                <React.Fragment>
                    <CardInfo
                        text={`${paymentLinkInfo.paymentLocalAmount} ${formatCurrencyCodeSign(paymentLinkInfo.paymentLocalCurrency)}`}
                        icon={<HomeIcon/>}
                        hint={"Amount of payments in the system balance currency"}
                    />
                    { hasSeparateBalance && (
                        <CardInfo
                            text={`${paymentLinkInfo.paymentBalanceAmount} ${formatCurrencyCodeSign(paymentLinkInfo.paymentBalanceCurrency)}`}
                            icon={<PersonIcon/>}
                            hint={"Amount of payments in the user's balance currency"}
                        />
                    )}
                    <CardAction
                        text={"Download"}
                        icon={<DownloadIcon/>}
                        internalLink
                        download="payment_qr_code.png"
                        to={paymentLinkInfo.qrCodeFileName}
                        onClick={closeVertMenu}
                    />
                    {inPopupMenu && (
                        <CopyLinkPrompt
                            payload={paymentLinkInfo}
                            onCopied={closeVertMenu}
                        />
                    )}
                </React.Fragment>
            )}
        />
    );
};

export default QRCodePaymentsCard;
