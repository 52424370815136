import React, {ReactNode} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Tooltip} from "@mui/material";


interface CardInfoProps {
    text: string;
    icon: ReactNode;
    hint: string;
}

const CardInfo = ({
                      text,
                      icon,
                      hint
                  }: CardInfoProps) => {


    return (
        <Tooltip disableFocusListener
                 title={hint}>
            <Grid
                container
                direction={"row"}
                alignItems={"center"}
                mb={1}
                flexWrap={"nowrap"}
                sx={{
                    textDecoration: "none",
                    color: "#636363",
                }}
            >
                <Grid item mr={2}>

                    <Box
                        sx={{
                            background: "white",
                            border: "1px solid #E8E6F8",
                            padding: "2px",
                            borderRadius: "5px",
                            lineHeight: 0,
                        }}
                    >
                        {icon}
                    </Box>

                </Grid>
                <Grid item zeroMinWidth>
                    <Typography noWrap variant={"body1"}>
                        {text}
                    </Typography>
                </Grid>
            </Grid>
        </Tooltip>
    );
};

export default CardInfo;