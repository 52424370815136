import {Helmet} from "react-helmet-async";
import {
    FormControl,
    InputLabel, Menu,
    MenuItem,
    OutlinedInput,
    Select,
    TableBody, TableFooter,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import React, {useCallback, useState} from "react";
import Grid from "@mui/material/Grid";
import dayjs, {Dayjs} from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {listAllPayoutsApiRoute, updatePayoutsStatusRoute, UpdatePayoutStatus} from "../../api/routes/payoutRoutes";
import PayoutStatusInfo from "../../model/PayoutStatusInfo";
import {useSnackbar} from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import {dashboardDate} from "../../utils/dateFormats";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import {formatCurrencyCodeSign} from "../../model/Payment";
import {APP_DEFAULT_CURRENCY} from "../../config";

interface PayoutStatusTableRowProps {
    payout: PayoutStatusInfo
    onSetDone?: () => void;
}

const PayoutStatusTableRow = ({
                                   payout,
                                   onSetDone
                               }: PayoutStatusTableRowProps) => {

    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | undefined>(undefined);

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    return (
        <TableRow
            key={payout.id}
        >
            <TableCell>{payout.externalID}</TableCell>
            <TableCell>{dashboardDate(payout.createdAt)}</TableCell>
            <TableCell>{payout.email}</TableCell>
            <TableCell>{payout.phone}</TableCell>
            <TableCell>{payout.amount} {formatCurrencyCodeSign(payout.currency)}</TableCell>
            <TableCell>{payout.balanceAmount} {formatCurrencyCodeSign(payout.balanceCurrency)}</TableCell>
            <TableCell>{payout.status}</TableCell>
            <TableCell>
                <React.Fragment>
                    <IconButton
                        aria-label="more"
                        id={`long-button-${payout.id}`}
                        aria-controls={`long-menu-${payout.id}`}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id={`long-menu-${payout.id}`}
                        MenuListProps={{
                            "aria-labelledby": `long-button-${payout.id}`,
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 1,
                            style: {
                                width: "20ch",
                            },
                        }}
                    >
                        <MenuItem
                            disabled={
                                payout.status === 'Done'
                            }
                            onClick={() => {
                                if (onSetDone) {
                                    onSetDone();
                                }
                                handleClose();
                            }}
                        >
                            <LibraryAddCheckIcon/>
                            Set 'Done'
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            </TableCell>
        </TableRow>
    );
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, personName: any, theme: any) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const payoutStatus = [
    'Initial',
    'Processing',
    'Done',
    'Error',
    'ErrorBalance'
];

interface IPoutFormState {
    fromDate: Dayjs | null,
    toDate: Dayjs | null,
    filterStatus: string[],
    payouts: PayoutStatusInfo[] | undefined,
    page: number,
    rowsPerPage: number,
    systemAmountTotal: string,
    systemCurrency: string,
}

const PayoutsPage = () => {

    const theme = useTheme();

    const [isSelecting, setIsSelecting] = useState<boolean>(false);

    const {enqueueSnackbar} = useSnackbar();

    const handleStatusChange = (event: any) => {
        const {
            target: {value},
        } = event;
        let status = typeof value === 'string' ? value.split(',') : value;
        setFormState({...formState, filterStatus: status});
    };

    const [formState, setFormState] = useState(() => {
        const savedState = sessionStorage.getItem("payoutPageState");
        let initialState: IPoutFormState = {
            fromDate: dayjs().subtract(7, 'day'),
            toDate: dayjs(),
            filterStatus: payoutStatus,
            payouts: undefined,
            page: 0,
            rowsPerPage: 5,
            systemAmountTotal: "0.00",
            systemCurrency: formatCurrencyCodeSign(APP_DEFAULT_CURRENCY),
        }
        if (savedState) {
            let initialStateString = JSON.parse(savedState) as any;
            initialState = {
                ...initialStateString,
                fromDate: dayjs(initialStateString.fromDate),
                toDate: dayjs(initialStateString.toDate)
            }
        }
        return initialState;
    })

    const selectPayouts = async () => {
        try {
            setIsSelecting(true)
            if (formState.toDate && formState.fromDate) {
                const retrievedPayouts = await listAllPayoutsApiRoute(formState.fromDate, formState.toDate, formState.filterStatus)
                const sumOfPayouts = retrievedPayouts
                    .map((item) => {
                        return {
                            sumSystemCurrency: Number(item.amount)
                        }
                    })
                    .reduce((prev, current) => {
                        return {
                            sumSystemCurrency: prev.sumSystemCurrency + current.sumSystemCurrency,
                        };
                    }, {
                        sumSystemCurrency: 0,
                    })
                const updatedState =  {...formState, page: 0, payouts: retrievedPayouts, systemAmountTotal: sumOfPayouts.sumSystemCurrency.toFixed(2)}
                setFormState(updatedState);
                sessionStorage.setItem("payoutPageState", JSON.stringify(updatedState));
            }
        } catch (error: any) {
            enqueueSnackbar(`Something went wrong: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setIsSelecting(false)
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setFormState({...formState, page: newPage});
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState({...formState, page: 0, rowsPerPage: parseInt(event.target.value, 10)});
    };

    const setPayoutStatusDone = useCallback(
        async (payoutID: string) => {
            try {
                const request: UpdatePayoutStatus = {
                    status: "done"
                }
                await updatePayoutsStatusRoute(payoutID, request);
                if (formState.payouts) {
                    const newState = formState.payouts.map(payout => {
                        if (payoutID === payout.id) {
                            payout.status = "Done";
                            return {...payout};
                        }
                        return payout;
                    });
                    setFormState({...formState, payouts: newState});
                }
                enqueueSnackbar("Payout status was set to done", {
                    variant: "success",
                });
            } catch (error: any) {
                enqueueSnackbar(error.message, {
                    variant: "error",
                });
            }
        }, [formState, enqueueSnackbar]
    )



    return (
        <React.Fragment>
            <Helmet>
                <title>Payouts for the selected period</title>
            </Helmet>
            <Typography variant="h1" mt={7}>
                Payouts for the selected period
            </Typography>
            <Grid container columnSpacing={2} rowSpacing={1} mt={4}>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            label="From date"
                            value={formState.fromDate}
                            onChange={(newValue) => {
                                setFormState({...formState, fromDate: newValue});
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            label="To date"
                            value={formState.toDate}
                            onChange={(newValue) => {
                                setFormState({...formState, toDate: newValue});
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <FormControl sx={{width: 300}}>
                        <InputLabel>Selected payment status</InputLabel>
                        <Select
                            labelId="payout-status-select-label"
                            id="payout-status-select"
                            multiple
                            value={formState.filterStatus}
                            onChange={handleStatusChange}
                            input={<OutlinedInput label="Payout status"/>}
                            MenuProps={MenuProps}
                        >
                            {payoutStatus.map((status) => (
                                <MenuItem
                                    key={status}
                                    value={status}
                                    style={getStyles(status, payoutStatus, theme)}
                                >
                                    {status}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <LoadingButton
                        size="large"
                        sx={{width: "100%"}}
                        variant="contained"
                        onClick={() => selectPayouts()}
                        loading={isSelecting}
                    >
                        Select payouts
                    </LoadingButton>
                </Grid>
            </Grid>
            <Grid container mt={4}>
                {isSelecting || !formState.payouts ? (
                    <Grid item>
                        <Typography variant={"body2"}>Please select payouts...</Typography>
                    </Grid>
                ) : (
                    <Grid item xs>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>CreatedAt</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>System amount</TableCell>
                                        <TableCell>Balance amount</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {formState.payouts.slice(formState.page * formState.rowsPerPage, formState.page * formState.rowsPerPage + formState.rowsPerPage).map((payout) => (
                                        <PayoutStatusTableRow key={payout.id}
                                                               payout={payout}
                                                               onSetDone={() => setPayoutStatusDone(payout.id)}
                                        />
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell>
                                            Total payouts amount in system currency :
                                        </TableCell>
                                        <TableCell>
                                            {formState.systemAmountTotal} {formState.systemCurrency}
                                        </TableCell>
                                        <TableCell colSpan={6}>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                colSpan={8}
                                                count={formState.payouts.length}
                                                component="div"
                                                rowsPerPage={formState.rowsPerPage}
                                                page={formState.page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    )
}

export default PayoutsPage;