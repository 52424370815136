import {Helmet} from "react-helmet-async";
import React, {useCallback, useEffect, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import UserInviteInfo from "../../model/UserInviteInfo";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {useSnackbar} from "notistack";
import {getUserInvitesForAdminApiRoute} from "../../api/routes/users";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {
    debounce,
    Grid, TableBody, TableFooter,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import {dashboardDate} from "../../utils/dateFormats";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UserEmailInviteDialog from "../dialogs/UserEmailInviteDialog";
import ContactMailIcon from "@mui/icons-material/ContactMail";

interface UserInviteTableRowProps {
    invite: UserInviteInfo
    onEmailDialog?: () => void;
}

const UserInviteTableRow = ({invite, onEmailDialog}: UserInviteTableRowProps) => {

    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | undefined>(undefined);

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    return (
        <TableRow key={invite.id}>
            <TableCell>{invite.email}</TableCell>
            <TableCell>{invite.job}</TableCell>
            <TableCell>{invite.establishmentName}</TableCell>
            <TableCell>{invite.senderEmail}</TableCell>
            <TableCell>{dashboardDate(invite.createdAt)}</TableCell>
            <TableCell>
                <React.Fragment>
                    <IconButton
                        aria-label="more"
                        id={`invite-button-${invite.id}`}
                        aria-controls={`invite-menu-${invite.id}`}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id={`invite-menu-${invite.id}`}
                        MenuListProps={{
                            "aria-labelledby": `invite-button-${invite.id}`,
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 1,
                            style: {
                                width: "20ch",
                            },
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                if (onEmailDialog) {
                                    onEmailDialog();
                                }
                                handleClose();
                            }}
                        >
                            <ContactMailIcon/>
                            Send invite
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            </TableCell>
        </TableRow>
    );
};

interface IInviteFormState {
    fromDate: Dayjs | null,
    toDate: Dayjs | null,
    filter: string | null,
}

const UserInvitesPage = () => {

    const [isSelecting, setIsSelecting] = useState<boolean>(false);

    const {enqueueSnackbar} = useSnackbar();

    const [page, setPage] = React.useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [invites, setInvites] = useState([] as UserInviteInfo[])

    const [selectedInvite, setSelectedInvite] = useState<UserInviteInfo | undefined>(undefined);

    const [formState, setFormState] = useState({
        fromDate: dayjs().subtract(7, 'day'),
        toDate: dayjs(),
        filter: "",
    } as IInviteFormState)

    const debouncedSearch = React.useMemo(
        () =>
            debounce(async (active, filter, fromDate, toDate) => {
                try {
                    if (fromDate && toDate) {
                        setIsSelecting(true);
                        const pageRows = await getUserInvitesForAdminApiRoute(fromDate, toDate);
                        if (!active) {
                            return;
                        }
                        const filteredRows: UserInviteInfo[] = [];
                        if (pageRows && filter && filter.length > 0) {
                            filteredRows.push(...pageRows.filter((row: UserInviteInfo) => {
                                if (row.email && row.email.toLowerCase().includes(filter.toLowerCase())) {
                                    return true;
                                }
                                return row.senderEmail.includes(filter.toLowerCase());
                            }));
                        } else {
                            filteredRows.push(...pageRows);
                        }
                        setInvites(filteredRows);
                        setPage(0);
                    }
                } catch (error: any) {
                    enqueueSnackbar(`Something went wrong: ${error.message}`, {
                        variant: "error",
                    });
                } finally {
                    setIsSelecting(false);
                }
            }, 1000),
        [enqueueSnackbar]
    )

    const getSearchResults = useCallback(
        (active, filter, fromDate, toDate) => {
            void debouncedSearch(active, filter, fromDate, toDate)
        },
        [debouncedSearch]
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    useEffect(() => {
        let active = true;
        getSearchResults(active, formState.filter, formState.fromDate, formState.toDate)
        return () => {
            active = false;
        };
    }, [formState, getSearchResults]);

    const showEmailInviteDialogForUser = useCallback(
        (invite: UserInviteInfo) => {
            setSelectedInvite(invite);
        }, []
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>User invites</title>
            </Helmet>
            <UserEmailInviteDialog
                invite={selectedInvite}
                onWantsToClose={() => {
                    setSelectedInvite(undefined);
                }}
            />
            <Typography variant="h1" mt={7}>
                Invitations from owners of organizations for the period
            </Typography>
            <Grid container columnSpacing={2} rowSpacing={1} mt={4}>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            label="From date"
                            value={formState.fromDate}
                            onChange={(newValue) => {
                                setFormState({...formState, fromDate: newValue});
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            label="To date"
                            value={formState.toDate}
                            onChange={(newValue) => {
                                setFormState({...formState, toDate: newValue});
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={true}>
                    <TextField
                        sx={{width: "100%"}}
                        id="filter"
                        label="Filter"
                        helperText={"\u00a0"}
                        value={formState.filter}
                        onChange={(event) => {
                            setFormState({...formState, filter: event.target.value})
                        }}
                        required
                    />
                </Grid>
            </Grid>
            <div style={{height: 600, width: '100%'}}>
                <Grid container direction={"column"} width={"100%"} spacing={0} mt={4}>
                    {isSelecting || !invites ? (
                        <Grid item>
                            <Typography variant={"body2"}>No user invites selected...</Typography>
                        </Grid>
                    ) : (
                        <Grid item xs>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Job</TableCell>
                                            <TableCell>Establishment name</TableCell>
                                            <TableCell>Sender email</TableCell>
                                            <TableCell>Created at</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {invites.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((invite) => (
                                            <UserInviteTableRow key={invite.id}
                                                                invite={invite}
                                                                onEmailDialog={() =>  showEmailInviteDialogForUser(invite)}
                                            />
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    colSpan={8}
                                                    count={invites.length}
                                                    component="div"
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                </Grid>
            </div>
        </React.Fragment>
    )

}

export default UserInvitesPage;