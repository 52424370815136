import UserInviteInfo from "../../model/UserInviteInfo";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, Table, TableRow
} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {sendUserInviteApiRoute, SendUserInviteRequest} from "../../api/routes/users";
import {useSnackbar} from "notistack";

interface UserEmailInviteDialogProps {
    invite?: UserInviteInfo;
    onWantsToClose: () => void;
}

const UserEmailInviteDialog = ({
                                   invite,
                                   onWantsToClose,
                               }: UserEmailInviteDialogProps) => {


    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {enqueueSnackbar} = useSnackbar();

    const sendInvite = async () => {

        const request: SendUserInviteRequest = {
            establishment: invite!.establishmentId,
            email: invite!.email,
            job: invite!.job!,
        };

        setIsLoading(true);

        try {
            await sendUserInviteApiRoute(request);

            enqueueSnackbar("Invite request was successfully sent", {
                variant: "success",
            });

        } catch (error: any) {
            if (error.status === 422) {
                enqueueSnackbar(JSON.stringify(error.details), {
                    variant: "error",
                });
            } else {
                enqueueSnackbar(error.message, {
                    variant: "error",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={!!invite} maxWidth="md" disableRestoreFocus>
            <DialogTitle>
                User invite from organization owner
            </DialogTitle>
            <DialogContent>
                {invite && (
                    <React.Fragment>
                        <Grid item xs={12} sm={12}>
                            Repeat sending of user invite message. Message is below.
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>To email:</TableCell>
                                        <TableCell>{invite.email}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                disabled={true}
                                sx={{width: "100%"}}
                                id="inviteText"
                                label="Invite email text"
                                value={invite.payload}
                                multiline={true}
                                required
                                rows={10}
                                style={{textAlign: 'left'}}
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </DialogContent>
            <DialogActions>
                {invite && (<LoadingButton
                        variant={"contained"}
                        loading={isLoading}
                        onClick={sendInvite}
                    >
                        Resend email
                    </LoadingButton>
                )}
                <Button
                    variant={"outlined"}
                    disabled={isLoading}
                    onClick={() => onWantsToClose()}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserEmailInviteDialog;