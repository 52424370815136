import {ThemeProvider} from "@mui/material";
import {NavLink, useHistory} from "react-router-dom";
import sidebarTheme from "./themes/sidebarTheme";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { formatUserName } from "../model/User";
import useRootStore from "../store/useRootStore";
import AppLogo from "./shared/AppLogo";
import ListItemIcon from "@mui/material/ListItemIcon";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ListItemButton from "@mui/material/ListItemButton";
import LogoutIcon from "@mui/icons-material/Logout";
import SupportIcon from "@mui/icons-material/SupportAgent";
import PaymentsIcon from '@mui/icons-material/Payments';
import PayoutsIcon from '@mui/icons-material/Outbound';
import HomeIcon from "@mui/icons-material/HomeOutlined";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

const Sidebar = (() => {

    const history = useHistory();
    const activeUserStore = useRootStore().activeUserStore;

    const drawerContent = (
        <Box
            component={"nav"}
            sx={{
                color: "#ffffff",
                height: "100vh",
                overflow: "auto",
                background: "#333333",
                scrollbarColor: "#555 rgba(0, 0, 0, 0) ",
            }}
        >
            <ThemeProvider theme={sidebarTheme}>
                <Grid
                    container
                    direction={"column"}
                    height={"100%"}
                    flexWrap={"nowrap"}
                >
                    <Grid
                        item
                        width={"100%"}
                        sx={{ textAlign: "center" }}
                        pt={5}
                        pb={7}
                    >
                        <AppLogo marginLeft={-66} size="small" />
                    </Grid>
                    <Grid item width={"100%"}>
                        <List dense disablePadding>
                            <ListItem
                                sx={{
                                    paddingTop: "8px",
                                    paddingRight: "16px",
                                    paddingBottom: "24px",
                                    paddingLeft: "48px",
                                }}
                            >
                                <ListItemText
                                    disableTypography
                                    primary={formatUserName(activeUserStore.me!)}
                                />
                            </ListItem>
                            <ListItem
                                button
                                component={NavLink}
                                to="/"
                                activeClassName={"active"}
                                exact
                            >
                                <ListItemIcon>
                                    <EqualizerIcon />
                                </ListItemIcon>
                                <ListItemText disableTypography primary="Users" />
                            </ListItem>
                            <ListItem
                                button
                                component={NavLink}
                                to="/payments"
                                activeClassName={"active"}
                                exact
                            >
                                <ListItemIcon>
                                    <PaymentsIcon />
                                </ListItemIcon>
                                <ListItemText disableTypography primary="Payments" />
                            </ListItem>
                            <ListItem
                                button
                                component={NavLink}
                                to="/payouts"
                                activeClassName={"active"}
                                exact
                            >
                                <ListItemIcon>
                                    <PayoutsIcon />
                                </ListItemIcon>
                                <ListItemText disableTypography primary="Payouts" />
                            </ListItem>
                            <ListItem
                                button
                                component={NavLink}
                                to="/establishments"
                                activeClassName={"active"}
                                exact
                            >
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText disableTypography primary="Establishments" />
                            </ListItem>
                            <ListItem
                                button
                                component={NavLink}
                                to="/ex-rates"
                                activeClassName={"active"}
                                exact
                            >
                                <ListItemIcon>
                                    <TrendingUpIcon />
                                </ListItemIcon>
                                <ListItemText disableTypography primary="Exchange rates" />
                            </ListItem>
                            <ListItem
                                button
                                component={NavLink}
                                to="/wallets"
                                activeClassName={"active"}
                                exact
                            >
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon />
                                </ListItemIcon>
                                <ListItemText disableTypography primary="Currency wallets" />
                            </ListItem>
                            <ListItem
                                button
                                component={NavLink}
                                to="/audit-logs"
                                activeClassName={"active"}
                                exact
                            >
                                <ListItemIcon>
                                    <AutoStoriesIcon />
                                </ListItemIcon>
                                <ListItemText disableTypography primary="Audit logs" />
                            </ListItem>
                            <ListItem
                                button
                                component={NavLink}
                                to="/user-invites"
                                activeClassName={"active"}
                                exact
                            >
                                <ListItemIcon>
                                    <InsertInvitationIcon />
                                </ListItemIcon>
                                <ListItemText disableTypography primary="User invites" />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item width={"100%"} sx={{ marginTop: "auto" }}>
                        <List dense>
                            <ListItem disablePadding>
                                <ListItemButton
                                    onClick={(e) => {
                                        window.location.href = "mailto:support@itips.it";
                                        e.preventDefault();
                                    }}
                                >
                                    <ListItemIcon>
                                        <SupportIcon />
                                    </ListItemIcon>
                                    <ListItemText disableTypography primary="Support" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    onClick={() => {
                                        activeUserStore.clear();
                                        history.replace("/");
                                    }}
                                >
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText disableTypography primary="Log Out" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </Box>
    )

    return (
        <>
            <Drawer
                sx={{
                    display: {xs: "none", md: "block"},
                    width: "292px",
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: "292px",
                        boxSizing: "border-box",
                        border: "none",
                        borderTopRightRadius: "90px",
                        overflowY: "auto",
                        overflowX: "hidden",
                    },
                }}
                variant="permanent"
                anchor="left"
                open
            >
                {drawerContent}
            </Drawer>
        </>
    );

});

export default Sidebar;