import {deleteApiRequest, getApiRequest, postApiRequest, putApiRequest} from "../index";
import User, {UserBalanceItem, UserStats, UserStatsBalance} from "../../model/User";
import {Dayjs} from "dayjs";
import AuditLogItemInfo from "../../model/AuditLogItemInfo";
import UserInviteInfo from "../../model/UserInviteInfo";

export const listUsersApiRoute = async () => {
    return (await getApiRequest("users")) as UserStats[];
};

export const getUsersApiRoute = async (userId: string) => {
    return (await getApiRequest(`admin/users/${userId}/operations`)) as UserStats;
};

export const getUserStatsApiRoute = async (userId: string) => {
    return (await getApiRequest(`users/${userId}`)) as UserStatsBalance;
};

export const deleteUserApiRoute = async (userId: string) => {
    return await deleteApiRequest(`users/${userId}`);
};

export interface UpdateUserStatsRequest {
    country?: string;
    countryCode?: string;
    company?: string;
    industry?: string;
    profession?: string;
    amlCheck: boolean;
    firstName: string;
    lastName: string;
    tipsCheck: boolean;
    zeroCommission: boolean;
    payTheme: string;
    lang: string;
    didConfirmEmail: boolean;
    identityVerificationStatus: string;
    balanceCurrency: string;
}

export const updateUserApiRoute = async (
    userID: string,
    request: UpdateUserStatsRequest
) => {
    return (await putApiRequest(`users/${userID}`, request)) as User;
};

export const getUserBalanceApiRoute = async (userID: string) => {
    return (await getApiRequest(`admin/users/${userID}/balance`)) as UserBalanceItem[];
};

export interface CreateUserBalanceRequest {
    userID: string
}

export const postUserBalanceIndexApiRoute = async (userID: string, request: CreateUserBalanceRequest) => {
    return (await postApiRequest(`admin/users/${userID}/balance/rebuild`, request));
}

export interface UserEmailConfirmationResponse {
    payload: string;
    createdAt: string;
    email: string;
}

export const getLastUserEmailConfirmationApiRoute = async (userID: string) => {
    return (await getApiRequest(`admin/users/${userID}/email-confirmations/last`)) as UserEmailConfirmationResponse;
};

export const postUserEmailConfirmationApiRoute = async (userID: string) => {
    return (await postApiRequest(`admin/users/${userID}/email-confirmations/last`, null));
}

export interface SendUserInviteRequest {
    establishment: string;
    job: string;
    email: string;
}

export const sendUserInviteApiRoute = async (
    request: SendUserInviteRequest
) => {
    return await postApiRequest(`admin/user-invites`, request);
};

export const toggleUserImpersonationRoute = async (userId: string) => {
    return await postApiRequest(`admin/users/${userId}/impersonate`, null);
};

export const listAuditLogsApiRoute = async (fromDate: Dayjs, toDate: Dayjs) => {
    return (await getApiRequest(`admin/audit-logs?from=${fromDate.toJSON()}&to=${toDate.toJSON()}`)) as AuditLogItemInfo[];
}

export const getUserInvitesForAdminApiRoute = async (fromDate: Dayjs, toDate: Dayjs) => {
    return (await getApiRequest(`admin/user-invites?from=${fromDate.toJSON()}&to=${toDate.toJSON()}`)) as UserInviteInfo[];
};
