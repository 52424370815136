import React, {useCallback, useEffect, useState, useMemo} from "react";
import {Grid, Typography, Box, TextField, debounce, Tooltip, Link} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {
    DataGrid,
    GridActionsCellItem,
    GridRowsProp,
    GridColumns,
    GridRenderCellParams,
} from '@mui/x-data-grid';
import {formatUserStatsCompany, formatUserValidation, UserStats} from "../../model/User";
import {deleteUserApiRoute, listUsersApiRoute, toggleUserImpersonationRoute} from "../../api/routes/users";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import QrCodeIcon from '@mui/icons-material/QrCode';
import IndividualIcon from "@mui/icons-material/Person";
import Badge from "@mui/material/Badge";
import OrganizationIcon from "@mui/icons-material/Group";
import AdminIcon from "@mui/icons-material/ManageAccounts";
import UserEditDialog from "../dialogs/UserEditDialog";
import EditIcon from '@mui/icons-material/Edit';
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {dashboardDate} from "../../utils/dateFormats";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import BalanceUpdateDialog from "../dialogs/BalanceUpdateDialog";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import UserEmailConfirmationDialog from "../dialogs/UserEmailConfirmationDialog";
import {APP_DEFAULT_CURRENCY} from "../../config";


const RenderCompany = (props: GridRenderCellParams<UserStats>) => {
    return (
        <Box sx={{maxHeight: 'inherit', lineHeight: '16px', width: '100%', whiteSpace: 'initial'}}>
            {formatUserStatsCompany(props.row)}
        </Box>
    )
};

const RenderAmlCheck = (props: GridRenderCellParams<UserStats>) => {

    const individualIcon = (
        <IndividualIcon/>
    )

    const individualIconImpersonation = (
        <Badge color="success" variant="dot">
            <IndividualIcon/>
        </Badge>
    )

    const individualIconValidated = (
        <Badge color="secondary" variant="dot">
            <IndividualIcon/>
        </Badge>
    )

    const orgIcon = (
        <OrganizationIcon/>
    )

    const orgIconImpersonation = (
        <Badge color="success" variant="dot">
            <OrganizationIcon/>
        </Badge>
    )

    const orgIconValidated = (
        <Badge color="secondary" variant="dot">
            <OrganizationIcon/>
        </Badge>
    )

    const adminIcon = (
        <AdminIcon/>
    )

    const adminValidated = (
        <Badge color="secondary" variant="dot">
            <AdminIcon/>
        </Badge>
    )

    const iconType = props.row.accountType

    const validated = props.row.amlCheck

    const impersonation = props.row.impersonated

    if (iconType === "individual") {
        if (impersonation) {
            return individualIconImpersonation
        } else if (validated) {
            return individualIconValidated
        } else {
            return individualIcon
        }
    } else if (iconType === "organization") {
        if (impersonation) {
            return orgIconImpersonation
        } else if (validated) {
            return orgIconValidated
        } else {
            return orgIcon
        }
    } else {
        if (validated) {
            return adminValidated
        } else {
            return adminIcon
        }
    }
};

const DashboardPage = () => {

    const [loadingUsers, setLoadingUsers] = useState(true);
    const [rows, setRows] = useState<GridRowsProp<UserStats>>([]);
    const [filteredRows, setFilteredRows] = useState<GridRowsProp<UserStats>>([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [selectedUser, setSelectedUser] = useState<UserStats | undefined>(undefined);

    const [userEditDialogIsOpen, setUserEditDialogIsOpen] = useState<boolean>(false);

    const [userEmailConfirmationDialogIsOpen, setUserEmailConfirmationDialogIsOpen] = useState<boolean>(false);

    const [balanceUpdateDialogIsOpen, setBalanceUpdateDialogIsOpen] = useState<boolean>(false);

    const [filter, setFilter] = useState(() => {
        const savedFilter = localStorage.getItem("dashboardFilter");
        let initialValue = ""
        if (savedFilter) {
            initialValue = JSON.parse(savedFilter);
        }
        return initialValue;
    })

    const history = useHistory();

    const {enqueueSnackbar} = useSnackbar();

    const deleteUser = useCallback(
        async (userId: string) => {
            try {
                if (window.confirm("Are You sure you want to delete this user?")) {
                    await deleteUserApiRoute(userId);
                    const index = rows.findIndex((user) => user.id === userId);
                    if (index !== -1) {
                        setRows((prevRows) => prevRows.filter((row) => row.id !== userId));
                        setFilteredRows((prevRows) => prevRows.filter((row) => row.id !== userId));
                    }
                }
            } catch (error: any) {
                enqueueSnackbar(`Can't delete user: ${error.message}`, {
                    variant: "error",
                });
            }
        },
        [rows, enqueueSnackbar]
    );

    const showEditDialogForUser = useCallback(
        (user: UserStats) => {
            setSelectedUser(user);
            setUserEditDialogIsOpen(true);
        }, []
    );

    const showBalanceRefreshDialogForUser = useCallback(
        (user: UserStats) => {
            setSelectedUser(user);
            setBalanceUpdateDialogIsOpen(true);
        }, []
    );

    const navigateToUserDetails = useCallback(
        (user: UserStats) => {
            history.push(`/users/${user.id}`);
        }, [history]
    );

    const navigateToBalanceDetails = useCallback(
        (user: UserStats) => {
            history.push(`/users/${user.id}/balance`);
        }, [history]
    )

    const navigateToUserQRCodes = useCallback(
        (user: UserStats) => {
            history.push(`/users/${user.id}/qr-codes`);
        }, [history]
    )

    const navigateToUserOperations = useCallback(
        (user: UserStats) => {
            history.push(`/users/${user.id}/operations`);
        }, [history]
    );

    const showEmailConfirmationDialogForUser = useCallback(
        (user: UserStats) => {
            setSelectedUser(user);
            setUserEmailConfirmationDialogIsOpen(true);
        }, []
    );

    const loadUsers = useCallback(async () => {
        try {
            const result = await listUsersApiRoute();
            setRows(result)
        } catch (error: any) {
            enqueueSnackbar(`Can't load users: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setLoadingUsers(false);
        }
    }, [enqueueSnackbar]);

    const handleUserUpdate = useCallback((user: UserStats) => {
        const newState = rows.map(item => {
            if (item.id === user.id) {
                return {...user};
            }
            return item;
        });

        setRows(newState);

        const newFilteredState = filteredRows.map(item => {
            if (item.id === user.id) {
                return {...user};
            }
            return item;
        });

        setFilteredRows(newFilteredState);
    }, [rows, filteredRows] );

    const toggleImpersonation = useCallback(
        async (userID: string) => {
            try {
                await toggleUserImpersonationRoute(userID);
                loadUsers().then();
            } catch (error: any) {
                enqueueSnackbar(`Can't toggle user impersonation: ${error.message}`, {
                    variant: "error",
                });
            }
        }, [enqueueSnackbar, loadUsers]
    );


    const columns = useMemo<GridColumns<UserStats>>(() => [
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1,
            valueGetter: (params: any) =>
                `${dashboardDate(params.row.createdAt)} `,
            sortComparator: (a: string, b: string) => {
                return new Date(a) > new Date(b) ? 1 : -1
            },
        },
        {
            field: 'userName',
            headerName: 'User Name',
            flex: 1,
            renderCell: (params: any) => (
                <Link onClick={() => {
                    navigateToUserDetails(params.row)
                }} style={{cursor: 'pointer'}} color={"#636363"} underline={"hover"}>
                    {params.row.lastName || ''} {params.row.firstName || ''}
                </Link>
            ),
            valueGetter: (params: any) =>
                `${params.row.lastName || ''} ${params.row.firstName || ''}`,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1.5,
        },
        {
            field: 'validation',
            headerName: 'Validation',
            flex: 0.5,
            renderCell: (params: any) => (
                <Tooltip disableFocusListener title={<span><p>E &mdash; email is confirmed</p><p>SS &mdash; SumSub is confirmed</p><p>AML &mdash; AML is confirmed</p></span>}>
                    <span>{formatUserValidation(params.row)}</span>
                </Tooltip>
            ),
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone number',
            flex: 1,
        },
        {
            field: 'paymentsAmount',
            headerName: 'Payment amount',
            flex: 0.5,
            sortComparator: (a: string, b: string) =>
                Number(a) - Number(b),
            renderCell: (params: any) => (
                <>{params.value} {APP_DEFAULT_CURRENCY}</>
            ),
        },
        {
            field: 'payoutsAmount',
            headerName: 'Payout amount',
            flex: 0.5,
            sortComparator: (a: string, b: string) =>
                Number(a) - Number(b),
            renderCell: (params: any) => (
                <>{params.value} {APP_DEFAULT_CURRENCY}</>
            ),
        },
        {
            field: 'amlCheck',
            headerName: 'Ind/Org/Adm',
            flex: 0.5,
            filterable: false,
            sortable: false,
            renderCell: RenderAmlCheck,
        },
        {
            field: 'place',
            headerName: 'Country/Company',
            flex: 1.5,
            filterable: false,
            sortable: false,
            renderCell: RenderCompany,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                // @ts-ignore
                <GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Edit user"
                    onClick={() => showEditDialogForUser(params.row)}
                    showInMenu={true}
                />,
                // @ts-ignore
                <GridActionsCellItem
                    icon={<TransferWithinAStationIcon/>}
                    label="Toggle impersonation"
                    onClick={() => toggleImpersonation(params.row.id)}
                    showInMenu={true}
                />,
                // @ts-ignore
                <GridActionsCellItem
                    icon={<ManageAccountsIcon/>}
                    label="Account details"
                    onClick={() => navigateToUserDetails(params.row)}
                    showInMenu={true}
                />,
                // @ts-ignore
                <GridActionsCellItem
                    icon={<ContactMailIcon/>}
                    label="Send email confirmation"
                    onClick={() => showEmailConfirmationDialogForUser(params.row)}
                    showInMenu={true}
                />,
                // @ts-ignore
                <GridActionsCellItem
                    icon={<AccountBalanceIcon/>}
                    label="Account operations"
                    onClick={() => navigateToUserOperations(params.row)}
                    showInMenu={true}
                />,
                // @ts-ignore
                <GridActionsCellItem
                    icon={<AccountBalanceWalletIcon/>}
                    label="Account balance"
                    onClick={() => navigateToBalanceDetails(params.row)}
                    showInMenu={true}
                />,
                // @ts-ignore
                <GridActionsCellItem
                    icon={<QrCodeIcon/>}
                    label="User QR-codes"
                    onClick={() => navigateToUserQRCodes(params.row)}
                    showInMenu={true}
                />,
                // @ts-ignore
                <GridActionsCellItem
                    icon={<BuildCircleIcon/>}
                    label="Rebuild balance index"
                    onClick={() => showBalanceRefreshDialogForUser(params.row)}
                    showInMenu={true}
                />,
                // @ts-ignore
                <GridActionsCellItem
                    icon={<PersonRemoveIcon/>}
                    label="Delete"
                    onClick={() => deleteUser(params.row.id)}
                    showInMenu={true}
                />,

            ],
        }
    ], [deleteUser, showEditDialogForUser, navigateToUserOperations, navigateToUserDetails, navigateToBalanceDetails, navigateToUserQRCodes, showBalanceRefreshDialogForUser, showEmailConfirmationDialogForUser, toggleImpersonation])

    useEffect(() => {
        loadUsers().then();
    }, [loadUsers]);

    const handleEditDialogClose = async () => {
        setUserEditDialogIsOpen(false);
        setSelectedUser(undefined);
    }

    const handleBalanceDialogClose = async () => {
        setBalanceUpdateDialogIsOpen(false);
        setSelectedUser(undefined);
    }

    const handleUserEmailConfirmationDialogClose = async () => {
        setUserEmailConfirmationDialogIsOpen(false);
        setSelectedUser(undefined);
    }


    // eslint-disable-next-line
    const getFilteredResults = useCallback(
        debounce((rows: any, filter: string, active: boolean) => {
            if (!active) {
                return;
            }
            if (rows && filter && filter.length > 0) {
                setFilteredRows(rows.filter((row: any) => {
                    if (row.email && row.email.toLowerCase().includes(filter.toLowerCase())) {
                        return true;
                    }
                    if ((row.firstName && row.firstName.toLowerCase().includes(filter.toLowerCase())) || (row.lastName && row.lastName.toLowerCase().includes(filter.toLowerCase()))) {
                        return true;
                    }
                    return row.phoneNumber.includes(filter.toLowerCase());
                }));
            } else {
                setFilteredRows(rows)
            }
        }, 300),
        []
    );


    useEffect(() => {
        let active = true;
        getFilteredResults(rows, filter, active)
        localStorage.setItem("dashboardFilter", JSON.stringify(filter));
        return () => {
            active = false;
        };
    }, [rows, filter, getFilteredResults]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <UserEditDialog
                isOpen={userEditDialogIsOpen}
                user={selectedUser}
                onClose={handleEditDialogClose}
                onUpdate={handleUserUpdate}
            />
            <BalanceUpdateDialog
                isOpen={balanceUpdateDialogIsOpen}
                user={selectedUser}
                onClose={handleBalanceDialogClose}
            />
            <UserEmailConfirmationDialog
                isOpen={userEmailConfirmationDialogIsOpen}
                user={selectedUser}
                onClose={handleUserEmailConfirmationDialogClose}
            />
            <Grid container direction={"column"} width={"100%"} spacing={0}>
                <Grid item mt={7}>
                    <Typography variant={"h1"}>Users dashboard</Typography>
                </Grid>
                <Grid mt={4} container>
                    {loadingUsers ? (
                        <Typography variant={"body2"}>Loading users...</Typography>
                    ) : (
                        <Grid container flexDirection={"column"}>
                            <Grid item xs={true}>
                                <TextField
                                    sx={{width: "100%"}}
                                    id="search"
                                    label="Search"
                                    helperText={"\u00a0"}
                                    value={filter}
                                    onChange={(event) => {
                                        setFilter(event.target.value)
                                    }}
                                    required
                                />
                            </Grid>
                            <div style={{height: '700px', width: '100%'}}>
                                <DataGrid
                                    rows={filteredRows}
                                    columns={columns}
                                    pagination
                                    pageSize={rowsPerPage}
                                    page={page}
                                    rowsPerPageOptions={[10, 50, 100]}
                                    rowCount={filteredRows.length}
                                    onPageChange={(newPage) => {
                                        setPage(newPage);
                                    }}
                                    onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                                    loading={loadingUsers}
                                />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default DashboardPage;