import {Helmet} from "react-helmet-async";
import React, {useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import PaymentLinkInfo from "../../model/PaymentLinkInfo";
import {Breadcrumbs, FormControlLabel, Grid, Link, Switch, TextField, Typography} from "@mui/material";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useSnackbar} from "notistack";
import {listUserPaymentLinksApiRoute} from "../../api/routes/paymentRoutes";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import QRCodePaymentsCard from "../cards/QRCodePaymentsCard";

interface MatchParams {
    id?: string;
}

interface IQRFormState {
    fromDate: Dayjs | null,
    toDate: Dayjs | null,
    paymentLinks: PaymentLinkInfo[] | undefined,
    page: number,
    rowsPerPage: number,
    onlyFilled: boolean
}

const UserQRCodesPage = () => {

    const [isSelecting, setIsSelecting] = useState<boolean>(false);

    const userID = useRouteMatch<MatchParams>().params.id;

    const [formState, setFormState] = useState(() => {
        let initialState: IQRFormState = {
            fromDate: dayjs().subtract(7, 'day'),
            toDate: dayjs(),
            paymentLinks: undefined,
            page: 0,
            rowsPerPage: 5,
            onlyFilled: false
        }
        return initialState;
    })

    const {enqueueSnackbar} = useSnackbar();

    const history = useHistory();

    const selectPaymentLinksInfo = async () => {
        try {
            setIsSelecting(true)
            if (formState.toDate && formState.fromDate && userID) {
                const retrievedLinks = await listUserPaymentLinksApiRoute(formState.fromDate, formState.toDate, userID)
                setFormState({...formState, page: 0, paymentLinks: retrievedLinks});
            }
        } catch (error: any) {
            enqueueSnackbar(`Something went wrong: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setIsSelecting(false)
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>User direct QR-Codes</title>
            </Helmet>
            <Typography variant="h1" mt={7}>
                User direct QR-Codes
            </Typography>
            <Grid container mt={4}>
                <Breadcrumbs>
                    <Link underline="hover" onClick={() => {
                        history.goBack()
                    }} style={{cursor: 'pointer'}}>Back to users</Link>
                    <Typography>User direct QR-Codes</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid container columnSpacing={2} rowSpacing={1} mt={4}>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            label="From date"
                            value={formState.fromDate}
                            onChange={(newValue) => {
                                setFormState({...formState, fromDate: newValue});
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            label="To date"
                            value={formState.toDate}
                            onChange={(newValue) => {
                                setFormState({...formState, toDate: newValue});
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        sx={{width: "100%"}}
                        value="start"
                        control={
                            <Switch
                                color="primary"
                                checked={formState.onlyFilled}
                                onChange={(event) => {
                                    setFormState({...formState, onlyFilled: event.target.checked})
                                }}
                            />
                        }
                        label="Codes with payments"
                        labelPlacement="start"
                    />
                </Grid>
                <Grid item>
                    <LoadingButton
                        size="large"
                        sx={{width: "100%"}}
                        variant="contained"
                        onClick={() => selectPaymentLinksInfo()}
                        loading={isSelecting}
                    >
                        Select QR-codes
                    </LoadingButton>
                </Grid>
            </Grid>
            <Grid container mt={4}>
                {isSelecting || !formState.paymentLinks ? (
                    <Grid item>
                        <Typography variant={"body2"}>  User own payment links are in the list below. Payments for QR-cored are calculated for the selected period. Please select QR-codes...</Typography>
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        {formState.paymentLinks.filter( item => {
                            return !formState.onlyFilled || item.paymentLocalAmount !== "0.00"
                        }).map((link) => (
                            <Grid key={link.id} item xs={12} xl={6} width={"100%"}>
                                <QRCodePaymentsCard
                                    showActions
                                    paymentLinkInfo={link}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    )
}

export default UserQRCodesPage;