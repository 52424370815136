import AuditLogItemInfo from "../../model/AuditLogItemInfo";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {useMemo} from "react";

interface AuditLogDialogProps {
    isOpen: boolean;
    auditLogItem: AuditLogItemInfo;
    onClose: () => void;
}

const AuditLogDialog = ({isOpen, auditLogItem, onClose}:AuditLogDialogProps) => {

    const close = () => {
        onClose();
    };

    const formattedPayload = useMemo(() => {
        if (auditLogItem) {
            let jsonPayload:any = JSON.parse(auditLogItem.eventData);
            return JSON.stringify(jsonPayload, null, 2);
        } else {
            return "";
        }
    }, [auditLogItem])

    return (
        <Dialog open={isOpen} fullWidth maxWidth={"md"} disableRestoreFocus>
            <DialogTitle>Audit event payload</DialogTitle>
            {auditLogItem && (<DialogContent>
                    <Grid container columnSpacing={2} rowSpacing={1} pl={2} pr={2} mt={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                disabled={true}
                                sx={{width: "100%"}}
                                id="eventData"
                                label="EventData"
                                value={formattedPayload}
                                multiline={true}
                                required
                                rows={9}
                                style={{textAlign: 'left'}}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            )}
            <DialogActions>
                <Button variant={"outlined"} onClick={() => close()}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AuditLogDialog;