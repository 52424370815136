import {formatUserName, UserStats} from "../../model/User";
import TextField from "@mui/material/TextField";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, Tooltip, MenuItem} from "@mui/material";
import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import CountrySelect, {countries, CountryType, getCountry} from "../shared/CountrySelect";
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import {useSnackbar} from "notistack";
import {updateUserApiRoute, UpdateUserStatsRequest} from "../../api/routes/users";
import {validateNonBlankString} from "../../utils/validation";

interface UserEditDialogProps {
    isOpen: boolean;
    user?: UserStats;
    onClose: () => void;
    onUpdate: (user: UserStats) => void;
}

interface FormErrors {
    company: string | null;
    industry: string | null;
    profession: string | null;
    firstName: string | null;
    lastName: string | null;
}

interface PaymentLinkThemeType {
    id: string
    name: string
}

const paymentLinkThemes: readonly PaymentLinkThemeType[] = [
    {id: 'default', name: 'Default theme'},
    {id: 'gophr', name: 'Gophr theme'},
    {id: 'gorillas', name: 'Gorillas theme'},
    {id: 'syncsfera', name: 'Synchrosfera theme'}
];

export interface UserLangType {
    code: string;
    label: string;
    suggested?: boolean;
}

const languages: readonly UserLangType[] = [
    {code: 'EN', label: 'English'},
    {code: 'ES', label: 'Spanish'},
    {code: 'BR', label: 'Portuguese (Brazil)'},
    {code: 'IN', label: 'Indonesian'},
];

export interface IdentityVerificationStatus {
    code: string;
    label: string;
}

const identityVerifications: readonly IdentityVerificationStatus[] = [
    {code: 'not_initiated', label: 'Not initiated'},
    {code: 'in_process', label: 'In progress'},
    {code: 'success', label: 'Success'},
    {code: '', label: 'Empty'},
]

export interface BalanceCurrency{
    code: string,
    label: string;
}

const currencies: readonly BalanceCurrency[] = [
    {code: 'EUR', label: 'Euro'},
    {code: 'TRY', label: 'Turkish Lira'},
    {code: 'IDR', label: 'Indonesian Rupiah'},
]

interface IUserFormState {
    isUpdating: boolean,
    countryValue: CountryType,
    firstName: string,
    lastName: string,
    industry: string,
    company: string,
    country: string,
    countryCode: string,
    profession: string,
    payTheme: string,
    isAMLValid: boolean,
    isTipsAllowed: boolean,
    isZeroCommission: boolean,
    lang: string,
    didConfirmEmail: boolean,
    identityVerificationStatus: string,
    balanceCurrency: string,
}

const defaultFormState: IUserFormState = {
    isUpdating: false,
    countryValue: countries[0],
    country: countries[0].label,
    countryCode: countries[0].code,
    firstName: '',
    lastName: '',
    industry: '',
    company: '',
    profession: '',
    payTheme: 'default',
    isAMLValid: false,
    isTipsAllowed: false,
    isZeroCommission: false,
    lang: 'EN',
    didConfirmEmail: false,
    identityVerificationStatus: 'not_initiated',
    balanceCurrency: 'EUR',
}


const UserStatsDialog = ({isOpen, user, onClose, onUpdate}: UserEditDialogProps) => {

    const {enqueueSnackbar} = useSnackbar();

    const [formState, setFormState] = useState(() => {
        return {...defaultFormState};
    })

    useEffect(() => {
        if (user) {
            let toUpdate: IUserFormState = {
                isUpdating: defaultFormState.isUpdating,
                industry: user?.industry ?? defaultFormState.industry,
                countryCode: getCountry(user?.countryCode).code,
                countryValue: getCountry(user?.countryCode),
                country: getCountry(user?.countryCode).label,
                profession: user?.profession ?? defaultFormState.profession,
                company: user?.company ?? defaultFormState.company,
                firstName: user?.firstName ?? defaultFormState.firstName,
                lastName: user?.lastName ?? defaultFormState.lastName,
                isAMLValid: user?.amlCheck ?? defaultFormState.isAMLValid,
                isTipsAllowed: user?.tipsCheck ?? defaultFormState.isTipsAllowed,
                isZeroCommission: user?.zeroCommission ?? defaultFormState.isZeroCommission,
                payTheme: user?.payTheme ?? defaultFormState.payTheme,
                lang: user?.lang ?? defaultFormState.lang,
                didConfirmEmail: user?.didConfirmEmail ?? defaultFormState.didConfirmEmail,
                identityVerificationStatus: user?.identityVerificationStatus ?? defaultFormState.identityVerificationStatus,
                balanceCurrency: user?.balanceCurrency ?? defaultFormState.balanceCurrency,
            }
            setFormState(toUpdate);
        }
    }, [user]);


    const [formErrors, setFormErrors] = useState<FormErrors>({
        company: null,
        industry: null,
        profession: null,
        firstName: null,
        lastName: null,
    });

    const close = () => {
        setFormState({...defaultFormState});
        onClose();
    };

    const updateUserInfo = async () => {
        try {
            if (onUpdate && user) {
                const request: UpdateUserStatsRequest = {
                    country: formState.country,
                    countryCode: formState.countryCode,
                    company: formState.company,
                    industry: formState.industry,
                    profession: formState.profession,
                    amlCheck: formState.isAMLValid,
                    firstName: formState.firstName,
                    lastName: formState.lastName,
                    tipsCheck: formState.isTipsAllowed,
                    zeroCommission: formState.isZeroCommission,
                    payTheme: formState.payTheme,
                    lang: formState.lang,
                    didConfirmEmail: formState.didConfirmEmail,
                    identityVerificationStatus: formState.identityVerificationStatus,
                    balanceCurrency: formState.balanceCurrency,
                }
                const formErrors: FormErrors = {
                    firstName: validateNonBlankString(
                        request.firstName,
                        "Please enter your first name"
                    ),
                    lastName: validateNonBlankString(
                        request.lastName,
                        "Please enter your last name"
                    ),
                    company: null,
                    industry: null,
                    profession: null,
                };
                setFormErrors(formErrors);
                setFormState({...formState, isUpdating: true})
                await updateUserApiRoute(user.id, request);
                let newUser = {
                    ...user,
                    industry: formState.industry,
                    company: formState.company,
                    profession: formState.profession,
                    countryCode: formState.countryCode,
                    country: formState.country,
                    amlCheck: formState.isAMLValid,
                    firstName: formState.firstName,
                    lastName: formState.lastName,
                    zeroCommission: formState.isZeroCommission,
                    payTheme: formState.payTheme,
                    lang: formState.lang,
                    didConfirmEmail: formState.didConfirmEmail,
                    balanceCurrency: formState.balanceCurrency,
                    identityVerificationStatus: formState.identityVerificationStatus,
                };
                onUpdate(newUser);
            }
            close();
        } catch (error: any) {
            enqueueSnackbar(error.message, {
                variant: "error",
            });
        } finally {
            setFormState({...formState, isUpdating: false});
        }
    }

    return (
        <Dialog open={isOpen} fullWidth maxWidth={"md"} disableRestoreFocus>
            <DialogTitle>Edit {user && formatUserName(user)}</DialogTitle>
            <DialogContent>
                {user && (
                    <Grid container columnSpacing={2} rowSpacing={1} pl={2} pr={2} mt={4}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={formState.isUpdating}
                                sx={{width: "100%"}}
                                id="firstName"
                                label="First name"
                                error={!!formErrors.firstName}
                                helperText={formErrors.firstName || "\u00a0"}
                                value={formState.firstName}
                                onChange={(event) => {
                                    setFormState({...formState, firstName: event.target.value});
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={formState.isUpdating}
                                sx={{width: "100%"}}
                                id="lastName"
                                label="Last name"
                                error={!!formErrors.lastName}
                                helperText={formErrors.lastName || "\u00a0"}
                                required
                                value={formState.lastName}
                                onChange={(event) => {
                                    setFormState({...formState, lastName: event.target.value});
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CountrySelect
                                disabled={formState.isUpdating}
                                currValue={formState.countryValue}
                                sx={{width: "100%"}}
                                onFormat={(country, code) => {
                                    setFormState({...formState, country: country, countryCode: code});
                                }}
                                id="countrySelect"
                                label="Country"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={formState.isUpdating}
                                sx={{width: "100%"}}
                                id="industry"
                                label="Company industry"
                                error={!!formErrors.industry}
                                helperText={formErrors.industry || "\u00a0"}
                                value={formState.industry}
                                onChange={(event) => {
                                    setFormState({...formState, industry: event.target.value});
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={formState.isUpdating}
                                sx={{width: "100%"}}
                                id="company"
                                label="Company"
                                error={!!formErrors.company}
                                helperText={formErrors.company || "\u00a0"}
                                required
                                value={formState.company}
                                onChange={(event) => {
                                    setFormState({...formState, company: event.target.value});
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={formState.isUpdating}
                                sx={{width: "100%"}}
                                id="profession"
                                label="Profession"
                                error={!!formErrors.profession}
                                helperText={formErrors.profession || "\u00a0"}
                                required
                                value={formState.profession}
                                onChange={(event) => {
                                    setFormState({...formState, profession: event.target.value});
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                select
                                id="pay-theme-id"
                                label="Payment theme"
                                value={formState.payTheme}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            elevation: 1,
                                        },
                                    },
                                }}
                                InputProps={{
                                    id: "pay-theme-id"
                                }}
                                onChange={(event) => {
                                    setFormState({...formState, payTheme: event.target.value});
                                }}
                                helperText={
                                    "\u00a0"
                                }
                            >
                                {paymentLinkThemes.map((paymentLinkTheme) => (
                                    <MenuItem key={paymentLinkTheme.id} value={paymentLinkTheme.id}>
                                        {paymentLinkTheme.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                select
                                id="user-lang-id"
                                label="Interface language"
                                value={formState.lang}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            elevation: 1,
                                        },
                                    },
                                }}
                                InputProps={{
                                    id: "user-lang-id"
                                }}
                                onChange={(event) => {
                                    setFormState({...formState, lang: event.target.value});
                                }}
                                helperText={
                                    "\u00a0"
                                }
                            >
                                {languages.map((language) => (
                                    <MenuItem key={language.code} value={language.code}>
                                        {language.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                select
                                id="identity-verification-status-id"
                                label="Identity verification status"
                                value={formState.identityVerificationStatus}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            elevation: 1,
                                        },
                                    },
                                }}
                                InputProps={{
                                    id: "identity-verification-status-id"
                                }}
                                onChange={(event) => {
                                    setFormState({...formState, identityVerificationStatus: event.target.value});
                                }}
                            >
                                {identityVerifications.map((verification) => (
                                    <MenuItem key={verification.code} value={verification.code}>
                                        {verification.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                select
                                id="currencies-id"
                                label="User balance currency"
                                value={formState.balanceCurrency}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            elevation: 1,
                                        },
                                    },
                                }}
                                onChange={(event) => {
                                    setFormState({...formState, balanceCurrency: event.target.value});
                                }}
                            >
                                {currencies.map((currencyItem) => (
                                    <MenuItem key={currencyItem.code} value={currencyItem.code}>
                                        {currencyItem.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Tooltip disableFocusListener placement={"right-end"} title={
                                <p>If not checked, user can't receive payments</p>}>
                                <FormControlLabel
                                    control={<Checkbox checked={formState.isTipsAllowed} onChange={(event) => {
                                        setFormState({...formState, isTipsAllowed: event.target.checked});
                                    }}/>}
                                    label={
                                        <span> User allowed to receive tips </span>
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Tooltip disableFocusListener placement={"right-end"} title={
                                <p>If not checked, user can't create payout requests</p>}>
                                <FormControlLabel
                                    control={<Checkbox checked={formState.isAMLValid} onChange={(event) => {
                                        setFormState({...formState, isAMLValid: event.target.checked});
                                    }}/>}
                                    label={
                                        <span> User info is valid </span>
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Tooltip disableFocusListener placement={"right-end"} title={
                                <p>If checked than user has zero commission for payouts</p>}>
                                <FormControlLabel
                                    control={<Checkbox checked={formState.isZeroCommission} onChange={(event) => {
                                        setFormState({...formState, isZeroCommission: event.target.checked});
                                    }}/>}
                                    label={
                                        <span> Zero commission for user </span>
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Tooltip disableFocusListener placement={"right-end"} title={
                                <p>If not checked, email is confirmed</p>}>
                                <FormControlLabel
                                    control={<Checkbox checked={formState.didConfirmEmail} onChange={(event) => {
                                        setFormState({...formState, didConfirmEmail: event.target.checked});
                                    }}/>}
                                    label={
                                        <span> User's email is confirmed </span>
                                    }
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={() => close()}>
                    Cancel
                </Button>
                <LoadingButton
                    variant={"contained"}
                    loading={formState.isUpdating}
                    onClick={updateUserInfo}
                >
                    Update
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
};

export default UserStatsDialog;