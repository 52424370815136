export const isBlankString = (value: string | undefined) => {
    return value === undefined || value === null || value.length === 0;
};


export const validateNonBlankString = (
    value: string | undefined,
    messageWhenBlank: string
) => {
    if (isBlankString(value)) {
        return messageWhenBlank;
    }
    return null;
};

export const validateCurrencyISOCode = (
    value: string | undefined,
    messageWhenWrong: string
) => {
    if (!isBlankString(value)) {
        if (value!.length > 3) {
            return messageWhenWrong
        }
    }
    return null;
}