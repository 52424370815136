import React, {useCallback, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import AuditLogItemInfo from "../../model/AuditLogItemInfo";
import {listAuditLogsApiRoute} from "../../api/routes/users";
import {useSnackbar} from "notistack";
import {Helmet} from "react-helmet-async";
import {Grid, Menu, MenuItem, TableBody, TableFooter, TextField, Typography} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import {dashboardDate} from "../../utils/dateFormats";
import AuditLogDialog from "../dialogs/AuditLogDialog";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";


interface AuditLogTableRowProps {
    auditLogItem: AuditLogItemInfo
    onShowAuditData?: () => void;
}

const AuditLogTableRow = ({
                            auditLogItem,
                            onShowAuditData
                          }:AuditLogTableRowProps ) => {

    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | undefined>(undefined);

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    return (
        <TableRow
            key={auditLogItem.id}
        >
            <TableCell>{auditLogItem.id}</TableCell>
            <TableCell>{auditLogItem.tableName}</TableCell>
            <TableCell>{dashboardDate(auditLogItem.createdAt)}</TableCell>
            <TableCell>{auditLogItem.operationType}</TableCell>
            <TableCell>{auditLogItem.userEmail ?? "Not logged" }</TableCell>
            <TableCell>
                <React.Fragment>
                    <IconButton
                        aria-label="more"
                        id={`long-button-${auditLogItem.id}`}
                        aria-controls={`long-menu-${auditLogItem.id}`}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id={`long-menu-${auditLogItem.id}`}
                        MenuListProps={{
                            "aria-labelledby": `long-button-${auditLogItem.id}`,
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 1,
                            style: {
                                width: "20ch",
                            },
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                if (onShowAuditData) {
                                    onShowAuditData();
                                }
                                handleClose();
                            }}
                        >
                            <SettingsApplicationsIcon/>
                            Show payload
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            </TableCell>
        </TableRow>
    )
}

interface IAuditLogFormState {
    fromDate: Dayjs | null,
    toDate: Dayjs | null,
    logItems: AuditLogItemInfo[] | undefined,
    page: number,
    rowsPerPage: number
}

const AuditLogs = () => {

    const [isSelecting, setIsSelecting] = useState<boolean>(false);

    const [formState, setFormState] = useState(() => {
        let initialState: IAuditLogFormState = {
            fromDate: dayjs().subtract(1, 'day'),
            toDate: dayjs(),
            logItems: undefined,
            page: 0,
            rowsPerPage: 5,
        }
        return initialState;
    })

    const {enqueueSnackbar} = useSnackbar();

    const selectAuditLogItems = async () => {
        try {
            setIsSelecting(true)
            if (formState.toDate && formState.fromDate) {
                const retrievedLinks = await listAuditLogsApiRoute(formState.fromDate, formState.toDate)
                setFormState({...formState, page: 0, logItems: retrievedLinks});
            }
        } catch (error: any) {
            enqueueSnackbar(`Something went wrong: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setIsSelecting(false)
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setFormState({...formState, page: newPage});
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState({...formState, page: 0, rowsPerPage: parseInt(event.target.value, 10)});
    };

    const [auditLogDialogIsOpen, setAuditLogDialogIsOpen] = useState<boolean>(false);

    const [selectedAuditLogItem, setSelectedAuditLogItem] = useState<AuditLogItemInfo | undefined>(undefined)

    const handleAuditLogClose = async () => {
        setSelectedAuditLogItem(undefined);
        setAuditLogDialogIsOpen(false);
    }

    const showAuditLogDialog = useCallback(
        (auditLogItem: AuditLogItemInfo) => {
            setSelectedAuditLogItem(auditLogItem);
            setAuditLogDialogIsOpen(true);
        }, []
    );


    return (
        <React.Fragment>
            <Helmet>
                <title>Audit event log items for the period</title>
            </Helmet>
            <AuditLogDialog
                isOpen={auditLogDialogIsOpen}
                auditLogItem={selectedAuditLogItem!}
                onClose={handleAuditLogClose}
            />
            <Typography variant="h1" mt={7}>
                Audit event log items for the period
            </Typography>
            <Grid container columnSpacing={2} rowSpacing={1} mt={4}>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            label="From date"
                            value={formState.fromDate}
                            onChange={(newValue) => {
                                setFormState({...formState, fromDate: newValue});
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            label="To date"
                            value={formState.toDate}
                            onChange={(newValue) => {
                                setFormState({...formState, toDate: newValue});
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <LoadingButton
                        size="large"
                        sx={{width: "100%"}}
                        variant="contained"
                        onClick={() => selectAuditLogItems()}
                        loading={isSelecting}
                    >
                        Select events
                    </LoadingButton>
                </Grid>
            </Grid>
            <Grid container mt={4}>
                {isSelecting || !formState.logItems ? (
                    <Grid item>
                        <Typography variant={"body2"}> Audit log events for the period are in the list below. Please
                            select events...</Typography>
                    </Grid>
                ) : (
                    <Grid item xs>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Table Name</TableCell>
                                        <TableCell>CreatedAt</TableCell>
                                        <TableCell>Operation type</TableCell>
                                        <TableCell>User email</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {formState.logItems.slice(formState.page * formState.rowsPerPage, formState.page * formState.rowsPerPage + formState.rowsPerPage).map((logItem) => (
                                        <AuditLogTableRow key={logItem.id}
                                                          auditLogItem={logItem}
                                                          onShowAuditData={() => {
                                                              showAuditLogDialog(logItem);
                                                          }}
                                        />
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                colSpan={6}
                                                count={formState.logItems.length}
                                                component="div"
                                                rowsPerPage={formState.rowsPerPage}
                                                page={formState.page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    )
}
export default AuditLogs