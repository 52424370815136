import {dashboardDate} from "../utils/dateFormats";
import PaymentStatusInfo from "./PaymentStatusInfo";

export default interface Payment {
    id: string;
    externalID: string;
    payType: string;
    currency: string;
    amount: string;
    status: string;
    comment?: string;
    doneAt: string;
}

type CurrencySignMapType = Record<string, string>;

const CurrencySignMap:CurrencySignMapType  = {
    "EUR":  '\u20AC',
    "GBP":  '\u00A3',
    "TRY":  '\u20BA',
    "IDR": 'Rp',
    "RSD": 'Din'
}

export const formatCurrencyCodeSign = (code: string | undefined) => {
    if (code && code.length > 0) {
        return CurrencySignMap[code]
    } else {
        return ''
    }
}

export const formatPayment = (payment: Payment) => {
    return `${payment.externalID} ${dashboardDate(payment.doneAt)}`
};

export const formatPaymentStatus = (payment: PaymentStatusInfo) => {
    return `${payment.transactionAmount} ${formatCurrencyCodeSign(payment.transactionCurrency)} ${payment.paymentType} ${payment.paymentSource} ${dashboardDate(payment.doneAt)}`
};

export interface PaymentCallback {
    id: string;
    doneAt: string;
    status: string;
    payType: string;
    payload: string;
}


