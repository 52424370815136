import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {FormControl, TableBody, TextField, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs, {Dayjs} from "dayjs";
import {APP_DEFAULT_CURRENCY} from "../../config";
import {validateCurrencyISOCode} from "../../utils/validation";
import LoadingButton from "@mui/lab/LoadingButton";
import ExchangeRatesInfo from "../../model/ExchangeRatesInfo";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import {listExchangeRates} from "../../api/routes/paymentRoutes";
import {useSnackbar} from "notistack";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import {dashboardDate} from "../../utils/dateFormats";

interface IExchangeRatesFormState {
    onDate: Dayjs | null,
    fCurr: string | undefined,
    tCurr: string | undefined,
    rates: ExchangeRatesInfo[] | undefined,
    page: number,
    rowsPerPage: number
}

interface FormErrors {
    fCurrError: string | null;
    tCurrError: string | null;
}

const ExchangeRatesPage = () => {

    const [formState, setFormState] = useState(() => {
        let initialState: IExchangeRatesFormState = {
            onDate: dayjs(),
            fCurr: undefined,
            tCurr: APP_DEFAULT_CURRENCY,
            rates: undefined,
            page: 0,
            rowsPerPage: 5
        }
        return initialState;
    })

    const [formErrors, setFormErrors] = useState<FormErrors>({
        fCurrError: null,
        tCurrError: null,
    });

    const {enqueueSnackbar} = useSnackbar();

    const [isSelecting, setIsSelecting] = useState<boolean>(false);

    const selectExchangeRates = async () => {
        try {
            setIsSelecting(true)
            if (formState.onDate) {
                const retrievedRates = await listExchangeRates(formState.onDate, formState.fCurr, formState.tCurr)
                setFormState({...formState, rates: retrievedRates});
            }
        } catch (error: any) {
            enqueueSnackbar(`Something went wrong: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setIsSelecting(false)
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setFormState({...formState, page: newPage});
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState({...formState, page: 0, rowsPerPage: parseInt(event.target.value, 10)});
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Currency exchange rate on date</title>
            </Helmet>
            <Typography variant="h1" mt={7}>
                Currency exchange rates on date
            </Typography>
            <Grid container columnSpacing={2} rowSpacing={1} mt={4}>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            label="On date"
                            value={formState.onDate}
                            onChange={(newValue) => {
                                setFormState({...formState, onDate: newValue});
                            }}
                            renderInput={(params) =>
                                <TextField
                                    helperText={
                                       "Last maintained exchange rate on date"
                                    }
                                {...params}
                            />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <FormControl sx={{width: 300}}>
                        <TextField
                            fullWidth
                            id="f-curr"
                            label="From currency"
                            value={formState.fCurr}
                            helperText={
                                formErrors.fCurrError || "All maintained currencies if empty"
                            }
                            onChange={(event) => {
                                const currencyValue = event.target.value.toUpperCase();
                                const newFormErrors = {
                                    ...formErrors,
                                    fCurrError: validateCurrencyISOCode(
                                        currencyValue,
                                        "Currency ISO code length is 3"
                                    ),
                                };
                                if (!newFormErrors.fCurrError) {
                                    setFormState({...formState, fCurr: currencyValue});
                                }
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl sx={{width: 300}}>
                        <TextField
                            fullWidth
                            id="t-curr"
                            label="To currency"
                            value={formState.tCurr}
                            helperText={
                                formErrors.tCurrError || "All maintained currencies if empty"
                            }
                            onChange={(event) => {
                                const currencyValue = event.target.value.toUpperCase();
                                const newFormErrors = {
                                    ...formErrors,
                                    tCurrError: validateCurrencyISOCode(
                                        currencyValue,
                                        "Currency ISO code length is 3"
                                    ),
                                };
                                setFormErrors(newFormErrors);
                                if (!newFormErrors.tCurrError) {
                                    setFormState({...formState, tCurr: currencyValue});
                                }
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <LoadingButton
                        size="large"
                        sx={{width: "100%"}}
                        variant="contained"
                        onClick={() => selectExchangeRates()}
                        loading={isSelecting}
                    >
                        Select exchange rates
                    </LoadingButton>
                </Grid>
            </Grid>
            <Grid container mt={4}>
                {isSelecting || !formState.rates ? (
                    <Grid item>
                        <Typography variant={"body2"}>Please select exchange rates...</Typography>
                    </Grid>
                ) : (
                    <Grid item xs>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ValueAt</TableCell>
                                        <TableCell>Rate</TableCell>
                                        <TableCell>From currency</TableCell>
                                        <TableCell>To currency</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {formState.rates.slice(formState.page * formState.rowsPerPage, formState.page * formState.rowsPerPage + formState.rowsPerPage).map((rate) => (
                                        <TableRow
                                            key={rate.id}
                                        >
                                            <TableCell>{dashboardDate(rate.valueAt)}</TableCell>
                                            <TableCell>{rate.rate}</TableCell>
                                            <TableCell>{rate.fCurr}</TableCell>
                                            <TableCell>{rate.tCurr}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={8}
                            count={formState.rates.length}
                            component="div"
                            rowsPerPage={formState.rowsPerPage}
                            page={formState.page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    )
}

export default ExchangeRatesPage;