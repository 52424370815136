import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { Alert, DialogActions, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    sendUserInviteApiRoute,
    SendUserInviteRequest,
} from "../../api/routes/users";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import {EstablishmentStats} from "../../model/Establishment";

interface InviteEmployeeDialogProps {
    isOpen: boolean;
    establishment?: EstablishmentStats;
    onWantToClose: (didSendInvite: boolean) => void;
}

const InviteEmployeeDialog = ({
                                  isOpen,
                                  establishment,
                                  onWantToClose,
                              }: InviteEmployeeDialogProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const emailInput = useRef<HTMLInputElement>(null);
    const jobInput = useRef<HTMLInputElement>(null);

    const [invitationError, setInvitationError] = useState<string | undefined>(
        undefined
    );

    const [selectedEstablishmentId, setSelectedEstablishmentId] = useState<
        string | undefined
        >(establishment ? establishment.id : undefined);

    const close = (inviteWasSent: boolean) => {
        setInvitationError(undefined);
        onWantToClose(inviteWasSent);
    };

    const sendInvite = async () => {
        const request: SendUserInviteRequest = {
            establishment: establishment
                ? establishment.id
                : selectedEstablishmentId!,
            email: emailInput.current!.value,
            job: jobInput.current!.value,
        };

        setIsSubmitting(true);

        try {
            const result = await sendUserInviteApiRoute(request);

            const isRedundantRequest = result === undefined;
            if (isRedundantRequest) {
                setInvitationError(
                    "A user with this email is already a member of your organization.No need for an invite - you can just add them to this establishment."
                );
            } else {
                enqueueSnackbar("Invite request was successfully sent", {
                    variant: "success",
                });
                close(true);
            }
        } catch (error: any) {
            if (error.status === 409) {
                setInvitationError(error.message);
            } else if (error.status === 422) {
                enqueueSnackbar(JSON.stringify(error.details), {
                    variant: "error",
                });
            } else {
                enqueueSnackbar(error.message, {
                    variant: "error",
                });
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (establishment) {
            setSelectedEstablishmentId(establishment.id);
        }
    }, [establishment]);


    useEffect(() => {
        if (!isOpen) {
            setIsLoading(false);
            setIsSubmitting(false);
        }
    }, [isOpen]);

    return (
        <Dialog open={isOpen} fullWidth maxWidth={"sm"} disableRestoreFocus>
            <DialogTitle>
                <Typography>Invite a New Employee</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction={"column"}>
                    {invitationError && (
                        <Grid item alignSelf={"center"} mt={3} mb={2}>
                            <Grow in>
                                <Alert
                                    variant={"filled"}
                                    severity="info"
                                    sx={{ maxWidth: "100%" }}
                                >
                                    {invitationError}
                                </Alert>
                            </Grow>
                        </Grid>
                    )}
                    {!selectedEstablishmentId && !isLoading && (
                        <Grid item mt={3} mb={2}>
                            <Typography variant={"body1"}>
                                There are no companies in your organization. To invite an
                                employee you must create at least one establishment.
                            </Typography>
                        </Grid>
                    )}
                    {selectedEstablishmentId && (
                        <React.Fragment>
                            <Grid item mt={2}>
                                <TextField
                                    required
                                    autoFocus
                                    inputRef={emailInput}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    helperText={"\u00a0"}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    inputRef={jobInput}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    id="job"
                                    label="Job"
                                    helperText={"\u00a0"}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"outlined"}
                    disabled={isSubmitting}
                    onClick={() => close(false)}
                >
                    Cancel
                </Button>
                {selectedEstablishmentId && (
                    <LoadingButton
                        variant={"contained"}
                        loading={isSubmitting}
                        onClick={sendInvite}
                    >
                        Send an Invite
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default InviteEmployeeDialog;
