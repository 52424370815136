import React, {useCallback, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    FormControl,
    Grid,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Select,
    TableBody,
    TableFooter,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import dayjs, {Dayjs} from 'dayjs';

import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import PaymentStatusInfo from "../../model/PaymentStatusInfo";
import {dashboardDate} from "../../utils/dateFormats";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import TablePagination from "@mui/material/TablePagination";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import {
    getPaymentStatusApiRoute,
    listAllPaymentsApiRoute,
    UpdatePaymentStatus,
    updatePaymentStatusRoute,
} from "../../api/routes/paymentRoutes";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import {formatCurrencyCodeSign} from "../../model/Payment";
import {APP_DEFAULT_CURRENCY} from "../../config";

interface PaymentStatusTableRowProps {
    payment: PaymentStatusInfo
    onGetStatus?: () => void;
    onSetDone?: () => void;
    onToCallbacks?: () => void;
}

function formatPaymentStatusID(payment: PaymentStatusInfo) {
    return (
        <React.Fragment>
            {`ID: ${payment.id}`}
            <br/>
            {`ExternalID: ${payment.externalID}`}
            <br/>
            {`PaymentType: ${payment.paymentType}`}
            <br/>
            {`PaymentSource: ${payment.paymentSource}`}
            {payment.agsID && (
                <React.Fragment>
                    <br/>
                    {`AgsID: ${payment.agsID}`}
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export function formatPaymentStatusInfo(item: PaymentStatusInfo): string {
    return `${item.transactionAmount} ${formatCurrencyCodeSign(item.transactionCurrency)} / ${item.balanceAmount} ${formatCurrencyCodeSign(item.balanceCurrency)} / ${item.systemAmount} ${formatCurrencyCodeSign(APP_DEFAULT_CURRENCY)}`;
}

const PaymentStatusTableRow = ({
                                   payment,
                                   onGetStatus,
                                   onSetDone,
                                   onToCallbacks
                               }: PaymentStatusTableRowProps) => {

    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | undefined>(undefined);

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    return (
        <TableRow
            key={payment.id}
        >
            <TableCell>{formatPaymentStatusID(payment)}</TableCell>
            <TableCell>{dashboardDate(payment.doneAt)}</TableCell>
            <TableCell>{payment.email}</TableCell>
            <TableCell>{payment.phone}</TableCell>
            <TableCell>{formatPaymentStatusInfo(payment)}</TableCell>
            <TableCell>{payment.status}</TableCell>
            <TableCell>
                <React.Fragment>
                    <IconButton
                        aria-label="more"
                        id={`long-button-${payment.id}`}
                        aria-controls={`long-menu-${payment.id}`}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id={`long-menu-${payment.id}`}
                        MenuListProps={{
                            "aria-labelledby": `long-button-${payment.id}`,
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 1,
                            style: {
                                width: "20ch",
                            },
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                if (onGetStatus) {
                                    onGetStatus();
                                }
                                handleClose();
                            }}
                        >
                            <EditIcon/>
                            Get Status
                        </MenuItem>
                        <MenuItem
                            disabled={
                                payment.status === 'Initial'
                            }
                            onClick={() => {
                                if (onToCallbacks) {
                                    onToCallbacks();
                                }
                                handleClose();
                            }}
                        >
                            <PhoneCallbackIcon/>
                            Callbacks
                        </MenuItem>
                        <MenuItem
                            disabled={
                                payment.status === 'Done'
                            }
                            onClick={() => {
                                if (onSetDone) {
                                    onSetDone();
                                }
                                handleClose();
                            }}
                        >
                            <LibraryAddCheckIcon/>
                            Set 'Done'
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            </TableCell>
        </TableRow>
    );
};

const paymentStatus = [
    'Initial',
    'Processing',
    'Done',
    'Error',
    'ErrorAGSCall',
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, personName: any, theme: any) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

interface IPayFormState {
    fromDate: Dayjs | null,
    toDate: Dayjs | null,
    filterStatus: string[],
    payments: PaymentStatusInfo[] | undefined,
    page: number,
    rowsPerPage: number,
    systemAmountTotal: string,
    systemCurrency: string,
}

const PaymentsPage = () => {

    const theme = useTheme();

    const [isSelecting, setIsSelecting] = useState<boolean>(false);

    const [formState, setFormState] = useState(() => {
        const savedState = sessionStorage.getItem("paymentPageState");
        let initialState: IPayFormState = {
            fromDate: dayjs().subtract(7, 'day'),
            toDate: dayjs(),
            filterStatus: paymentStatus,
            payments: undefined,
            page: 0,
            rowsPerPage: 5,
            systemAmountTotal: "0.00",
            systemCurrency: formatCurrencyCodeSign(APP_DEFAULT_CURRENCY),
        }
        if (savedState) {
            let initialStateString = JSON.parse(savedState) as any;
            initialState = {
                ...initialStateString,
                fromDate: dayjs(initialStateString.fromDate),
                toDate: dayjs(initialStateString.toDate)
            }
        }
        return initialState;
    })

    const {enqueueSnackbar} = useSnackbar();

    const history = useHistory();

    const selectPayments = async () => {
        try {
            setIsSelecting(true)
            if (formState.toDate && formState.fromDate) {
                const retrievedPayments = await listAllPaymentsApiRoute(formState.fromDate, formState.toDate, formState.filterStatus)
                const sumOfPayments = retrievedPayments
                    .map((item) => {
                        return {
                            sumSystemCurrency: Number(item.systemAmount)
                        }
                    })
                    .reduce((prev, current) => {
                        return {
                            sumSystemCurrency: prev.sumSystemCurrency + current.sumSystemCurrency,
                        };
                    }, {
                        sumSystemCurrency: 0,
                    })
                const updatedState = {
                    ...formState,
                    page: 0,
                    payments: retrievedPayments,
                    systemAmountTotal: sumOfPayments.sumSystemCurrency.toFixed(2)
                };
                setFormState(updatedState);
                sessionStorage.setItem("paymentPageState", JSON.stringify(updatedState));
            }
        } catch (error: any) {
            enqueueSnackbar(`Something went wrong: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setIsSelecting(false)
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setFormState({...formState, page: newPage});
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState({...formState, page: 0, rowsPerPage: parseInt(event.target.value, 10)});
    };

    const handleStatusChange = (event: any) => {
        const {
            target: {value},
        } = event;
        let status = typeof value === 'string' ? value.split(',') : value;
        setFormState({...formState, filterStatus: status});
    };

    const getPaymentStatus = useCallback(
        async (paymentID: string) => {
            try {
                const resp = await getPaymentStatusApiRoute(paymentID)
                if (formState.payments) {
                    const newState = formState.payments.map(payment => {
                        if (resp.id === payment.id) {
                            return {...resp};
                        }
                        return payment;
                    });
                    setFormState({...formState, payments: newState});
                }
                enqueueSnackbar("Payment status was updated successfully", {
                    variant: "success",
                });
            } catch (error: any) {
                enqueueSnackbar(error.message, {
                    variant: "error",
                });
            }
        }, [formState, enqueueSnackbar]
    )

    const navigateToPaymentCallbacks = useCallback(
        (paymentID: string) => {
            sessionStorage.setItem("paymentPageState", JSON.stringify(formState));
            history.push(`/payments/${paymentID}/callbacks`);
        }, [history, formState]
    );

    const setPaymentStatusDone = useCallback(
        async (paymentID: string) => {
            try {
                const request: UpdatePaymentStatus = {
                    status: "done"
                }
                await updatePaymentStatusRoute(paymentID, request);
                if (formState.payments) {
                    const newState = formState.payments.map(payment => {
                        if (paymentID === payment.id) {
                            payment.status = "Done";
                            return {...payment};
                        }
                        return payment;
                    });
                    setFormState({...formState, payments: newState});
                }
                enqueueSnackbar("Payment status was set to done", {
                    variant: "success",
                });
            } catch (error: any) {
                enqueueSnackbar(error.message, {
                    variant: "error",
                });
            }
        }, [formState, enqueueSnackbar]
    )

    return (
        <React.Fragment>
            <Helmet>
                <title>Payments for the selected period</title>
            </Helmet>
            <Typography variant="h1" mt={7}>
                Payments for the selected period
            </Typography>
            <Grid container columnSpacing={2} rowSpacing={1} mt={4}>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            label="From date"
                            value={formState.fromDate}
                            onChange={(newValue) => {
                                setFormState({...formState, fromDate: newValue});
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            label="To date"
                            value={formState.toDate}
                            onChange={(newValue) => {
                                setFormState({...formState, toDate: newValue});
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <FormControl sx={{width: 300}}>
                        <InputLabel>Selected payment status</InputLabel>
                        <Select
                            labelId="payment-status-select-label"
                            id="payment-status-select"
                            multiple
                            value={formState.filterStatus}
                            onChange={handleStatusChange}
                            input={<OutlinedInput label="Payment status"/>}
                            MenuProps={MenuProps}
                        >
                            {paymentStatus.map((status) => (
                                <MenuItem
                                    key={status}
                                    value={status}
                                    style={getStyles(status, paymentStatus, theme)}
                                >
                                    {status}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <LoadingButton
                        size="large"
                        sx={{width: "100%"}}
                        variant="contained"
                        onClick={() => selectPayments()}
                        loading={isSelecting}
                    >
                        Select payments
                    </LoadingButton>
                </Grid>
            </Grid>
            <Grid container mt={4}>
                {isSelecting || !formState.payments ? (
                    <Grid item>
                        <Typography variant={"body2"}>Please select payments...</Typography>
                    </Grid>
                ) : (
                    <Grid item xs>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>DoneAt</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>Transaction/Balance/System</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {formState.payments.slice(formState.page * formState.rowsPerPage, formState.page * formState.rowsPerPage + formState.rowsPerPage).map((payment) => (
                                        <PaymentStatusTableRow key={payment.id}
                                                               payment={payment}
                                                               onGetStatus={() => getPaymentStatus(payment.id)}
                                                               onSetDone={() => setPaymentStatusDone(payment.id)}
                                                               onToCallbacks={() => navigateToPaymentCallbacks(payment.id)}
                                        />
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell>
                                            Total payments amount in system currency :
                                        </TableCell>
                                        <TableCell>
                                            {formState.systemAmountTotal} {formState.systemCurrency}
                                        </TableCell>
                                        <TableCell colSpan={5}>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                colSpan={8}
                                                count={formState.payments.length}
                                                component="div"
                                                rowsPerPage={formState.rowsPerPage}
                                                page={formState.page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    )
};

export default PaymentsPage;