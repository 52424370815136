import React, {useCallback, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableRow, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import AcquiringKeyWalletInfo, {AcquiringKeyCard, AcquiringKeyMobile} from "../../model/AcquiringKeyWalletInfo";
import {listWallets, refreshWalletsCache} from "../../api/routes/paymentRoutes";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from "@mui/material/Grid";
import {formatCurrencyCodeSign} from "../../model/Payment";
import LoadingButton from "@mui/lab/LoadingButton";
import TableCell from "@mui/material/TableCell";

interface CardWalletInfoProps {
    walletInfo: AcquiringKeyCard;
    infoText: string;
}

const CardWalletInfo = ({walletInfo, infoText}: CardWalletInfoProps) => {
    return (
        <React.Fragment>
            <Typography variant={"body2"}>
                {infoText}
            </Typography>
            <Grid item sx={{display: {xs: "none", sm: "flex"}}}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell>Wallet URL</TableCell>
                            <TableCell>{walletInfo.paymentURL}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>API key</TableCell>
                            <TableCell>{walletInfo.apiKey} </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </React.Fragment>
    )
};

interface MobileWalletInfoProps {
    walletInfo: AcquiringKeyMobile;
    infoText: string;
}

const MobileWalletInfo = ({walletInfo, infoText}: MobileWalletInfoProps) => {
    return (
        <React.Fragment>
            <Typography variant={"body2"}>
                {infoText}
            </Typography>
            <Grid item sx={{display: {xs: "none", sm: "flex"}}}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell>Wallet URL</TableCell>
                            <TableCell>{walletInfo.paymentURL}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Service ID</TableCell>
                            <TableCell>{walletInfo.serviceID} </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </React.Fragment>
    )
}


const WalletsPage = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [loadingWallets, setLoadingWallets] = useState(true);

    const [rows, setRows] = useState<AcquiringKeyWalletInfo[]>([]);

    const clearWalletCache = useCallback(async () => {
        try {
            const result = await refreshWalletsCache();
            setRows(result)
            enqueueSnackbar("Wallet information was updated successfully", {
                variant: "success",
            });
        } catch (error: any) {
            enqueueSnackbar(`Can't load wallets information: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setLoadingWallets(false);
        }
    }, [enqueueSnackbar])

    const loadWallets = useCallback(async () => {
        try {
            const result = await listWallets();
            setRows(result)
        } catch (error: any) {
            enqueueSnackbar(`Can't load wallets information: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setLoadingWallets(false);
        }
    }, [enqueueSnackbar]);


    useEffect(() => {
        loadWallets().then();
    }, [loadWallets]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Wallets configuration for available currencies</title>
            </Helmet>
            <Typography variant="h1" mt={7}>
                Wallets configuration for available currencies
            </Typography>
            <Typography variant={"body2"} mt={4}>
                Wallet configuration is cached on application start. <br/> To update wallet cache "Clear wallets
                configuration cache" button may be used.
            </Typography>
            <Grid container columnSpacing={2} rowSpacing={1} mt={4}>
                {loadingWallets || !rows ? (
                    <Grid item>
                        <Typography variant={"body2"}>Selecting wallets configuration...</Typography>
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Grid item xs={6}>
                            {rows.map((walletCurrency) => (
                                <Accordion
                                    defaultExpanded={walletCurrency.currency === 'EUR'}
                                    key={walletCurrency.currency}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        {walletCurrency.currency} {formatCurrencyCodeSign(walletCurrency.currency)}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {walletCurrency.cardNoFee && (
                                            <CardWalletInfo walletInfo={walletCurrency.cardNoFee} infoText={"Wallet configuration for the card payments, no KFL"} />
                                        )}
                                        {walletCurrency.cardWithFee && (
                                            <CardWalletInfo walletInfo={walletCurrency.cardWithFee} infoText={"Wallet configuration for the card payments, with KFL"} />
                                        )}
                                        {walletCurrency.appleNoFee && (
                                            <MobileWalletInfo walletInfo={walletCurrency.appleNoFee} infoText={"Wallet configuration for the ApplePay payments, no KFL"} />
                                        )}
                                        {walletCurrency.appleWithFee && (
                                            <MobileWalletInfo walletInfo={walletCurrency.appleWithFee} infoText={"Wallet configuration for the ApplePay payments, with KFL"} />
                                        )}
                                        {walletCurrency.googleNoFee && (
                                            <MobileWalletInfo walletInfo={walletCurrency.googleNoFee} infoText={"Wallet configuration for the GooglePay payments, no KFL"} />
                                        )}
                                        {walletCurrency.googleWithFee && (
                                            <MobileWalletInfo walletInfo={walletCurrency.googleWithFee} infoText={"Wallet configuration for the GooglePay payments, with KFL"} />
                                        )}
                                    </AccordionDetails>
                                </Accordion>)
                            )}
                        </Grid>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item mt={4}>
                            <LoadingButton
                                size="large"
                                sx={{width: "100%"}}
                                variant="contained"
                                onClick={() => clearWalletCache()}
                                loading={loadingWallets}
                            >
                                Clear wallets configuration cache
                            </LoadingButton>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </React.Fragment>
    )
}

export default WalletsPage