import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CopyIcon from "@mui/icons-material/CopyAll";
import React from "react";
import removeIdPrefix from "../../utils/hashids";
import {useSnackbar} from "notistack";
import {THIS_APP_URL} from "../../config";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import PaymentLinkInfo from "../../model/PaymentLinkInfo";

interface CopyLinkPromptProps {
    payload: PaymentLinkInfo;
    onCopied?: () => void;
}

const CopyLinkPrompt = ({payload, onCopied}: CopyLinkPromptProps) => {

    const {enqueueSnackbar} = useSnackbar();

    const linkHash = removeIdPrefix(payload.id);

    const linkToCopy = buildLinkToCopy(payload)

    function buildLinkToCopy(payload: PaymentLinkInfo) {
        return `${THIS_APP_URL}/pay/${removeIdPrefix(payload.id)}`
    }

    return (
        <Grid
            container
            flexDirection={"row"}
            alignItems={"center"}
            flexWrap={"nowrap"}
            maxWidth={"100%"}
            justifyContent={"start"}
            mt={2}
            sx={{
                backgroundColor: "#EEEEEE",
                borderRadius: "25px",
                padding: "0px 8px 0px 16px",
            }}
        >
            <Grid item zeroMinWidth>
                <Link
                    underline="hover"
                    href={linkToCopy}
                    noWrap
                    target={"_blank"}
                    color={"GrayText"}
                    fontSize={16}
                    display={"block"}
                >
                    {linkHash}
                </Link>
            </Grid>
            <Grid item>
                <Tooltip title="Copy to Clipboard">
                    <IconButton
                        aria-label="copy"
                        sx={{marginLeft: 1}}
                        onClick={() => {
                            if (linkToCopy) {
                                navigator.clipboard.writeText(linkToCopy!).then();
                                enqueueSnackbar("The link is copied", {
                                    variant: "success",
                                });
                            }
                            if (onCopied) {
                                onCopied();
                            }
                        }}
                    >
                        <CopyIcon/>
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default CopyLinkPrompt;
