import {ReactComponent as QrCodeStubSVG} from "../../images/qr_code_stub.svg";
import Avatar from "@mui/material/Avatar";
import React from "react";
import PaymentLinkInfo from "../../model/PaymentLinkInfo";

interface QrCodeWrapperProps {
    paymentLink: PaymentLinkInfo;
}

const QrCodeWrapper = ({paymentLink}: QrCodeWrapperProps) => {

    return (
        <Avatar
            alt={""}
            variant={"rounded"}
            src={paymentLink.qrCodeFileName}
            sx={{
                width: {xs: "80px", sm: "120px"},
                height: {xs: "80px", sm: "120px"},
                borderWidth: {xs: "3px", sm: "5px"},
            }}
        >
            <QrCodeStubSVG/>
        </Avatar>
    );
};

export default QrCodeWrapper;
